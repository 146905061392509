import React, {useEffect} from 'react';

import i18n from "i18next";
import { useState } from "react";
import { Row, Col, Drawer, Affix } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { Button } from "../../common/Button";
import {
  AffixS,
  Divider,
  NavBbar,
  NavS,
  NavDropDown,
  NavDropDownItem,
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLink,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";
import {scrollTo} from "../../common/utils/utils";
import {SvgIcon} from "../../common/SvgIcon";
import {Image} from "../../common/Image";
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = ({ t, i18n }: any) => {
  const [visible, setVisibility] = useState(false);
  const [hideIcon, setHideIcon] = useState(false);

  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () =>
        window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 300;
    const winScroll = document.body.scrollTop ||
        document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      !hideIcon &&      // to limit setting state only the first time
      setHideIcon(true);
    } else {
      setHideIcon(false);
    }
  };

  const MenuItem = () => {
    const scrollAndHide = (id: string) => {
      scrollTo(id);
      setVisibility(false);
    }

    let recipeLinks = [];
    console.log(t('header.pageLinks.recipies'));
    if (typeof t('header.pageLinks.recipies') === 'object'){
      recipeLinks = t('header.pageLinks.recipies').map((i: any) => (
        <NavDropDownItem href={i.key}><Span>{i.value}</Span></NavDropDownItem>
    ))
    }

    return (  
    <>
      <NavBbar>
        <Container>
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          {/* <Navbar.Collapse id="basic-navbar-nav"> */}
            <NavS className="me-auto">
              <NavS.Link href="/"><Span>{t("header.about")}</Span></NavS.Link>
              <NavDropDown title={<Span>{t("header.recipies")}</Span>} id="basic-nav-dropdown" renderMenuOnMount={true}>
                {recipeLinks}
                <Divider />
                <NavDropDownItem href='https://www.instagram.com/dare.tocook'>{t("header.pageLinks.see-more.prefix")} <b>@dare.tocook</b> {t("header.pageLinks.see-more.sufix")}</NavDropDownItem>
              </NavDropDown>
            </NavS>
          {/* </Navbar.Collapse> */}
        </Container>
      </NavBbar>
      {/* CTA button */}
      <CustomNavLinkSmall
        style={{ width: "180px" }}
        onClick={() => scrollTo("contact")}
        >
        <Span>
          <Button>{t("cta.button")}</Button>
        </Span>
      </CustomNavLinkSmall>
      {/* Language selector */}
      <LanguageSwitchContainer>
        <LanguageSwitch onClick={() => handleChange("en")}
          className={i18n.language === "en" ? 'selected' : ''}>
          <SvgIcon
              src="english.svg"
              aria-label="homepage"
              width="25px"
              height="25px"
          />
        </LanguageSwitch>
        <LanguageSwitch onClick={() => handleChange("ro")}
          className={i18n.language === "ro" ? 'selected' : ''}>
          <SvgIcon
              src="ro.svg"
              aria-label="homepage"
              width="25px"
              height="25px"
          />
        </LanguageSwitch>
        <LanguageSwitch onClick={() => handleChange("hu")}
          className={i18n.language === "hu" ? 'selected' : ''}>
          <SvgIcon
              src="hu.svg"
              aria-label="homepage"
              width="25px"
              height="25px"
          />
        </LanguageSwitch>
      </LanguageSwitchContainer>
    </>
    
    );
  };

  return (
    <AffixS>
    <Affix offsetTop={0}>
      <HeaderSection>
        <Container>
          <Row style={{ height: "100%" }} justify="space-between">
            <Col>
              <Row>
                <Col>
                  <LogoContainer to="/" aria-label="homepage">
                    <Image src="logo_with_text.png" />
                  </LogoContainer>
                </Col>
                <Col>
                  <LogoContainer to="/" aria-label="homepage" >
                    D A R E T O C O O K
                  </LogoContainer>
                </Col>
              </Row>
            </Col>
            <Col>
                <NotHidden>
                  <MenuItem />
                </NotHidden>
                <Burger onClick={showDrawer}>
                  <Outline />
                </Burger>
            </Col>
          </Row>
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <Label onClick={onClose}>
                <Col span={12}>
                  <Menu>Menu</Menu>
                </Col>
                <Col span={12}>
                  <Outline />
                </Col>
              </Label>
            </Col>
            <MenuItem />
          </Drawer>
        </Container>
      </HeaderSection>
    </Affix>
    </AffixS>
  );
};

export default withTranslation()(Header);
