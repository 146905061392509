import { Col } from "antd";
import styled from "styled-components";

export const BannerBlockContainer = styled("section")<any>`
  position: relative;
  background-color: ${({theme}: any) => theme['dark']};
  background-image: URL(${(p:any) => (p.background ? '/assets/img/'+p.background : "")});
  background-position: center;
  background-size: cover;
  background-attachment: fixed ;
  height: calc(100vh - 100px);

  @media only screen and (max-width: 768px) {
    background-image: URL('/assets/img/banner_muffins_small.jpg');
    background-position-x: -200px;
  }

  img {
    @media only screen and (max-width: 768px) {
      height: 180px;
      width:200px;
    }
  }

  .ant-row{
    height: 100%;
  }

`;

export const BannerShadySide = styled(Col)<any>`
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 50%;
  padding: 14rem 8rem;
  text-align:center;

  h1{
    color: #fff;
    font-size: 72px;
    line-height: 1.25;

    @media only screen and (max-width: 1366px) {
      font-size: 40px;
    }

    @media only screen and (max-width: 414px) {
      font-size: 30px;
    }

    @media only screen and (max-width: 1024px) {
    }
  }

  p{
    color: #fff;
  }

  @media only screen and (max-width: 1024px) {
    padding: 14rem 2rem;
  }
  @media only screen and (max-width: 768px) {
    padding: 5rem 2rem;
    width: 100%;

  }
`;

export const BannerLightSide = styled(Col)<any>`
  height: 100%;
  width: 50%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;


export const Content = styled("p")`
  margin: 1.5rem 0 2rem 0;
`;

export const ContentWrapper = styled("div")`
  position: relative;
  max-width: 540px;

  @media only screen and (max-width: 575px) {
    padding-bottom: 4rem;
  }
`;

export const ButtonWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  max-width: 100%;

  @media screen and (min-width: 1024px) {
    max-width: 80%;
  }

  button:last-child {
    margin-left: 20px;
  }
`;
