import styled from "styled-components";
import {Navbar, NavDropdown, Nav} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Affix } from "antd";
import { MenuOutlined } from "@ant-design/icons";

export const AffixS = styled(Affix)`
  .ant-affix{  
    header{
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
    .show{
      background-image: linear-gradient(135deg,rgba(255,255,255,.95),rgba(255,255,255,.95)) !important;
    }
  }
`;

export const HeaderSection = styled("header")`
  background-color: hsla(0,0%,100%,0);
  background-image: linear-gradient(135deg,rgba(255,255,255,.9),rgba(255,255,255,.9));
  box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 4px 24px 0 rgb(0 0 0 / 8%);

  // @media only screen and (max-width: 1366px) {
  //   -webkit-backdrop-filter: blur(5px);
  //   backdrop-filter: blur(5px);
  // }

  @media only screen and (min-width: 890px) {
    height: 150px;
    padding: 0.5rem 5rem;
  }
  @media only screen and (max-width: 890px) {
    height: 100px;
    padding: 0.5rem 2rem;
  }
  
  // background-color: ${({theme}: any) => theme['white']};
  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
  .ant-row-end{
    align-items: center;
    text-align: center;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  font-size: 22px;
  align-items: center;
  height: 100%;
  color:  ${({theme}: any) => theme['dark']};
  
  &:hover{
    color:  ${({theme}: any) => theme['dark']};
  }

  img{
    width: 100px;
    height: 100px;
  }

  @media only screen and (max-width: 890px) {
    font-size: 12px;
    img {
      width: 80px;
      height: 80px
    }
  }
`;

export const NavLinkFake = styled("div")`
  display: inline-block;
  text-align: center;
`;
export const NavLink = styled(Link)`
  display: inline-block;
  text-align: center;
  span{
    font-size:19px;
  }
`;

// export const CustomNavLink = styled("div")`
//   width: 203px;
//   display: inline-block;

//   @media only screen and (max-width: 411px) {
//     width: 150px;
//   }

//   @media only screen and (max-width: 320px) {
//     width: 118px;
//   }
// `;

export const ContactWrapper = styled("div")<any>`
  cursor: pointer;
  width: ${(p) => (p.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled("div")`
  @media only screen and (max-width: 1366px) {
    display: block;
  }

  display: none;

  svg {
    fill: ${({theme}: any) => theme['dark']};
  }
`;

export const NotHidden = styled("div")`
  @media only screen and (max-width: 1366px) {
    display: none;
  }
`;

export const Menu = styled("h5")`
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  
  @media only screen and (max-width: 890px) {
    color: ${({theme}: any) => theme['dark']};
  }
`;

export const CustomNavLinkSmall = styled(NavLinkFake)`
  // font-size: 1.25rem;
  color: ${({theme}: any) => theme['dark']};
  transition: color 0.2s ease-in;
  margin: 0.5rem 0.8rem;

  @media only screen and (max-width: 890px) {
    color: ${({theme}: any) => theme['dark']};
  }
  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
  
  Button {
    margin: 0;

    background-color: hsla(0,0%,100%,0);
    background-image: linear-gradient(135deg,rgba(255,255,255,.20),rgba(255,255,255,.15));
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
`;

export const CustomNavLink = styled(NavLink)`
  // font-size: 1rem;
  color: ${({theme}: any) => theme['dark']};
  transition: color 0.2s ease-in;
  margin: 0.5rem 0.8rem;

  @media only screen and (max-width: 890px) {
    color: ${({theme}: any) => theme['dark']};
  }
  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
  
  Button {
    margin: 0;
  }
`;

export const Label = styled("span")`
  font-weight: 500;
  color: ${({theme}: any) => theme['dark']};
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const Outline = styled(MenuOutlined)<any>`
  font-size: 22px;
`;

export const Span = styled("span")`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: ${({theme}: any) => theme['accent']};
    text-underline-position: under;
    text-decoration: ${({theme}: any) => theme['light']} underline;
  }
`;



export const LanguageSwitch = styled("div")<any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  margin: 3px;

  &:hover,
  &:active,
  &:focus,
  &.selected {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  //display: flex;
  //justify-content: space-between;
  margin-left: 10px;
  width: 70px;
  display:inline-flex;
  justify-content: space-around;
`;

export const NavDropDownItem = styled(NavDropdown.Item)`
  &:focus,
  &:hover{
    background-color: rgba(0,0,0,0);
  }
`
export const NavDropDown = styled(NavDropdown)`
  .dropdown-menu{
    padding: 20px 20px 10px;

    &.show{
      top: 110%;
      left: -8%;

      border: none;

      @media only screen and (max-width: 1366px) {
        background: white;
      }

      background-color: hsla(0,0%,100%,0);
      background-image: linear-gradient(135deg,rgba(255,255,255,.72),rgba(255,255,255,.56));
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      box-shadow: 0 0 0 1px rgb(0 0 0 / 4%), 0 4px 24px 0 rgb(0 0 0 / 8%);
    }
  }
`;

export const Divider = styled(NavDropdown.Divider)`
  margin-top: 15px;
`;

export const NavS = styled(Nav)`
  @media only screen and (max-width: 1366px) {
    flex-direction: column !important;
  }
`;

export const NavBbar = styled(Navbar)`
  display: inline-block;
  a {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

