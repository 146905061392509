import React  from 'react';

import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
} from "./styles";
import {SvgIcon} from "../../common/SvgIcon";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {


  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row
              justify="center"
              align="middle"
          >
            <Col>
              <Title>{t("footer.followUs")}</Title>
            </Col>
          </Row>
          <Row
              justify="center"
              align="middle"
              style={{ paddingTop: "0.5rem" }}
          >
            <Col>
              <SocialLink
                  href="https://www.instagram.com/dare.tocook"
                  src="instagram.svg"
              />
            </Col>
          </Row>
        </Container>

      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
